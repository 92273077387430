export const IMAGE_TYPES = ['png', 'jpg', 'jpeg', 'webp', 'svg', 'gif']
export const VIDEO_TYPES = ['mp4', 'video/ogg', 'webm', 'quicktime']
export const AUDIO_TYPES = ['mp3', 'audio/ogg', 'wav', 'mpeg']
export const enumContentType = {
    HEADER: 'HEADER',
    FOOTER: 'FOOTER',
    BODY: 'BODY',
    BUTTONS: 'BUTTONS'
}
export const enumContentFormat = {
    TEXT: 'TEXT',
    IMAGE: 'IMAGE',
    VIDEO: 'VIDEO',
    DOCUMENT: 'DOCUMENT',
    LOCATION: 'LOCATION',
}
export const API_TYPE = {
    OFICIAL: 'OFICIAL',
    WWEBJS: 'WWEBJS',
}