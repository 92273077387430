/* eslint-disable camelcase */
/* eslint-disable no-return-assign */
// settings module
import { get, post } from '../../utils/api'

const state = {
  fetchingSettings: false,
  settings: {},
  settingsEnabled: {},
  isModalActive: false
}

const getters = {
  allSettings: state => {
    const settings = state.settings
    settings.message_start_ticket = settings.message_start_ticket?.replaceAll('\n', '<br>') || ''
    settings.message_end_ticket = settings.message_end_ticket?.replaceAll('\n', '<br>') || ''
    settings.interval_fetch_messages = settings.interval_fetch_messages || 30
    settings.business_hours_id = settings.business_hours_id || null
    settings.message_out_of_business_hours = settings.message_out_of_business_hours?.replaceAll('\n', '<br>') || ''

    settings.automatic_ticket_transfer_type = settings.automatic_ticket_transfer_type || 'NON'
    settings.department_id_transfer_ticket_to = settings.department_id_transfer_ticket_to || null
    settings.attendant_id_transfer_ticket_to = settings.attendant_id_transfer_ticket_to || null
    settings.interval_transfer_ticket_to = settings.interval_transfer_ticket_to || null
    settings.automatic_ticket_transfer_message = settings.automatic_ticket_transfer_message || ''
    settings.contacts_restrictions_type = settings.contacts_restrictions_type || ''

    settings.phone = settings.phone || null
    settings.name = settings.name || ''

    return settings
  },
  allSettingsEnabled: state => {
    const settings = state.settingsEnabled
    settings.message_start_ticket = settings.message_start_ticket?.replaceAll('\n', '<br>') || ''
    settings.message_end_ticket = settings.message_end_ticket?.replaceAll('\n', '<br>') || ''
    settings.interval_fetch_messages = settings.interval_fetch_messages || 30
    settings.business_hours_id = settings.business_hours_id || null
    settings.message_out_of_business_hours = settings.message_out_of_business_hours?.replaceAll('\n', '<br>') || ''

    settings.automatic_ticket_transfer_type = settings.automatic_ticket_transfer_type || 'NON'
    settings.department_id_transfer_ticket_to = settings.department_id_transfer_ticket_to || null
    settings.attendant_id_transfer_ticket_to = settings.attendant_id_transfer_ticket_to || null
    settings.interval_transfer_ticket_to = settings.interval_transfer_ticket_to || null
    settings.automatic_ticket_transfer_message = settings.automatic_ticket_transfer_message || ''
    settings.contacts_restrictions_type = settings.contacts_restrictions_type || ''

    settings.phone = settings.phone || null
    settings.name = settings.name || ''

    return settings
  },
  fetchingSettings: state => state.fetchingSettings,
  isModalActive: state => state.isModalActive,
  allPhonesEnabled: state => state.settingsEnabled.map(s => ({
    phone: s.phone,
    name: s.name,
    phoneAndName: (s.phone && s.name) ? `${s.name} - ${s.phone}` : null
  })),
  allPhones: state => state.settings.map(s => ({
    phone: s.phone,
    name: s.name,
    phoneAndName: (s.phone && s.name) ? `${s.name} - ${s.phone}` : null
  }))
}

const actions = {
  async fetchSettings ({ commit }) {
    commit('setFetchingSettings', true)
    const response = await get('settings')
    commit('setSettings', response.data)
    commit('setSettingsEnabled', response.data)
    commit('setFetchingSettings', false)
  },
  async saveSettings ({ commit, dispatch }, data) {
    commit('setFetchingSettings', true)
    await post('settings', data).catch((err) => {
      commit('setFetchingSettings', false)
      throw err
    })
    dispatch('fetchSettings')
  },
  async showModal ({ commit }) {
    commit('activeModal', true)
  },
  async hideModal ({ commit }) {
    commit('activeModal', false)
  }
}

const mutations = {
  setSettings: (state, settings) => state.settings = settings,
  setFetchingSettings: (state, fetching) => state.fetchingSettings = fetching,
  activeModal: (state, active) => state.isModalActive = active,
  setSettingsEnabled: (state, settings) => state.settingsEnabled = settings.filter(s => s.enabled)
}

export default {
  state, getters, actions, mutations
}
